import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import loc from 'Components/languages';
import Tab from 'Components/tab';
import Modal from 'Components/modal';
import LoadingOverlay from 'Components/loading-overlay';
import PromotionListItem from './PromotionListItem.jsx';
import MessageListItem from './MessageListItem.jsx';
import styles from './ModalPromotions.less';
import CriterionListItem from './CriterionListItem.jsx';
import InsertCouponCodeListItem from './InsertCouponCodeListItem.jsx';

class ModalPromotions extends PureComponent {
	constructor() {
		super();
		this.defaultSelectedPromotions = new Map({
			exclusive: new Map(),
			multiple: new Map(),
			other: new Map(),
		});
		this.state = {
			currentTab: 'promotions',
			viewPromotion: null,
			selectedPromotions: this.defaultSelectedPromotions,
		};
		this.tabs = [
			'promotions',
			'notification',
		];
		this.modalStyle = {
			content: {
				left: 'calc(50% - 400px)',
				right: 'calc(50% - 400px)',
				// maxHeight: '600px',
				padding: 0,
			},
		};
		this.isLoading = this.isLoading.bind(this);
		this.onClickPrevHandler = this.onClickPrevHandler.bind(this);
		this.onChangeTabHandler = this.onChangeTabHandler.bind(this);
		this.onSelectHandler = this.onSelectHandler.bind(this);
		this.onViewDetailsHandler = this.onViewDetailsHandler.bind(this);
		this.onClickApplyHandler = this.onClickApplyHandler.bind(this);
		this.renderCouponCode = this.renderCouponCode.bind(this);
		this.renderPromotions = this.renderPromotions.bind(this);
		this.renderMessages = this.renderMessages.bind(this);
	}

	componentDidUpdate(prevProps) {
		const salesOrder = this.props.salesOrder;
		const prevSalesOrder = prevProps.salesOrder;
		const isOpen = this.props.isOpen;
		const prevIsOpen = prevProps.isOpen;
		let newState = {};
		if (salesOrder && (salesOrder !== prevSalesOrder || isOpen && isOpen !== prevIsOpen)) {
			let selectedPromotions = this.defaultSelectedPromotions;
			if (salesOrder.get('promotion_ids_selected') && salesOrder.get('promotions')) {
				const promotionIdsSelected = salesOrder.get('promotion_ids_selected');
				['exclusive', 'multiple', 'other'].forEach((type) => {
					if (salesOrder.get('promotions')[type]) {
						salesOrder.get('promotions')[type].forEach((promotion) => {
							if (!promotion.pass) {
								return;
							}
							if (promotionIdsSelected.indexOf(promotion.promotion_id) !== -1) {
								selectedPromotions = selectedPromotions.set(
									type,
									selectedPromotions.get(type).set(promotion.promotion_id, promotion)
								);
							}
						});
					}
				});
			}
			newState.selectedPromotions = new Map(selectedPromotions);
		}

		if (isOpen && isOpen !== prevIsOpen) {
			newState.currentTab = isOpen;
			newState.viewPromotion = null;
		}

		this.setState(newState);
	}

	isLoading() {
		const { isLoading, giftCouponInfo } = this.props;
		return isLoading || giftCouponInfo.isFetching;
	}

	onClickPrevHandler() {
		const { viewPromotion } = this.state;
		if (viewPromotion) {
			this.setState({
				viewPromotion: null,
			});
		} else {
			this.props.onToggle();
		}
	}

	onChangeTabHandler(tab) {
		this.setState({
			currentTab: tab,
		});
	}

	onSelectHandler(type, promotion) {
		let { selectedPromotions } = this.state;
		let tmp = new Map();
		switch (type) {
		case 'exclusive':
			if (!selectedPromotions.get('exclusive').has(promotion.promotion_id)) {
				tmp = tmp.set(promotion.promotion_id, promotion);
			}
			selectedPromotions = selectedPromotions
				.set('exclusive', tmp)
				.set('multiple', new Map())
				.set('other', new Map())
			;
			break;
		case 'multiple':
			selectedPromotions = selectedPromotions
				.set('exclusive', new Map())
				.set('other', new Map())
			;
			if (selectedPromotions.get('multiple').has(promotion.promotion_id)) {
				selectedPromotions = selectedPromotions.set('multiple', selectedPromotions.get('multiple').remove(promotion.promotion_id));
			} else {
				selectedPromotions = selectedPromotions.set('multiple', selectedPromotions.get('multiple').set(promotion.promotion_id, promotion));
			}
			selectedPromotions = selectedPromotions
				.set('exclusive', new Map())
				.set('other', new Map())
			;
			break;
		case 'other':
			// if (!selectedPromotions.get('other').has(promotion.promotion_id)) {
			// 	tmp = tmp.set(promotion.promotion_id, promotion);
			// }
			selectedPromotions = selectedPromotions
				.set('exclusive', new Map())
				.set('multiple', new Map())
				.set('other', new Map(tmp))
			;
			break;
		}
		this.setState({
			selectedPromotions,
		});
	}

	onViewDetailsHandler(type, id) {
		this.setState({
			viewPromotion: {
				type,
				id,
			},
		});
	}

	onClickApplyHandler(e) {
		e && e.preventDefault();
		const { onApply, onToggle } = this.props;
		const { selectedPromotions } = this.state;
		let promotionIdsSelected = [
			...selectedPromotions.get('exclusive').keys(),
			...selectedPromotions.get('multiple').keys(),
			...selectedPromotions.get('other').keys(),
		];
		onApply && onApply(promotionIdsSelected);
		onToggle();
	}

	renderCouponCode(coupons) {
		return coupons.map((criterion) => {
			switch (criterion.type) {
			case 'single':
				return criterion.code;
			case 'pattern':
				return `${loc.pattern}${criterion.pattern}`;
			case 'range':
				return `${loc.rangeFrom}${criterion.from} ${loc.rangeTo} ${criterion.to}`;
			}
		}).join('\n');
	}

	renderPromotionDetails() {
		const { salesOrder } = this.props;
		let { viewPromotion } = this.state;
		if (!viewPromotion) {
			return;
		}
		viewPromotion = salesOrder.get('promotions')[viewPromotion.type].find((promotion) => promotion.promotion_id === viewPromotion.id);
		return viewPromotion && (
			<div className={ styles.promotionDetails }>
				<div className={ styles.promotionName }>
					{ viewPromotion.description || viewPromotion.promotion_name }
				</div>
				<div className={ styles.promotionDescription }>
					{ this.renderCouponCode(viewPromotion.conditions.coupons) }
				</div>
				<div className={ styles.criteria }>
					{
						viewPromotion.conditions.coupons.length > 0 && (
							<Fragment>
								<div className={ styles.subtitle }>{ loc.insertPromotionCode }</div>
								<div className={ styles.subsection }>
									{
										viewPromotion.conditions.coupons.map((condition, index) => (
											<InsertCouponCodeListItem
												key={ `criterion-list-item-coupon-${index}` }
												criterion={ condition }
											/>
										))
									}
								</div>
							</Fragment>
						)
					}

					{
						viewPromotion.conditions.fail.length > 0 && (
							<Fragment>
								<div className={ styles.subtitle }>{ loc.missingCriteria }</div>
								<div className={ styles.subsection }>
									{
										viewPromotion.conditions.fail.map((condition, index) => (
											<CriterionListItem
												key={ `criterion-list-item-fail-${index}` }
												criterion={ condition }
												fulfilled={ false }
											/>
										))
									}
								</div>
							</Fragment>
						)
					}

					{
						viewPromotion.conditions.pass.length > 0 && (
							<Fragment>
								<div className={ styles.subtitle }>{ loc.fulfilledCriteria }</div>
								<div className={ styles.subsection }>
									{
										viewPromotion.conditions.pass.map((condition, index) => (
											<CriterionListItem
												key={ `criterion-list-item-pass-${index}` }
												criterion={ condition }
												fulfilled={ true }
											/>
										))
									}
								</div>
							</Fragment>
						)
					}
				</div>
			</div>
		);
	}

	renderPromotions() {
		const { current, type, salesOrder } = this.props;
		const { currentTab, selectedPromotions } = this.state;
		const promotions = salesOrder.get('promotions');
		return this.renderPromotionDetails() || (
			<Fragment>
				{
					current === 'cart' && type === 'salesOrder' && (
						<div className={ styles.tab }>
							<Tab
								tabs={ this.tabs }
								current={ currentTab }
								onChange={ this.onChangeTabHandler }
							/>
						</div>
					)
				}
				<div className={ (current === 'cart' && type === 'salesOrder') ? styles.main : styles.mainFull }>
					<div className={ styles.title }>
						{ loc.exclusive }
					</div>
					<div className={ styles.list }>
						<ul className="uk-list uk-list-divider">
							{
								promotions && promotions.exclusive && promotions.exclusive.map((promotion, index) => (
									<PromotionListItem
										key={ `modal-promotions-exclusive-${index}` }
										type="exclusive"
										promotion={ promotion }
										isSelected={ selectedPromotions.get('exclusive').has(promotion.promotion_id) }
										onSelect={ this.onSelectHandler }
										onApply={ this.onClickApplyHandler }
										onViewDetails={ this.onViewDetailsHandler }
									/>
								))
							}
						</ul>
					</div>

					<div className={ styles.title }>
						{ loc.multiple }
					</div>
					<div className={ styles.list }>
						<ul className="uk-list uk-list-divider">
							{
								promotions && promotions.multiple && promotions.multiple.map((promotion, index) => (
									<PromotionListItem
										key={ `modal-promotions-multiple-${index}` }
										type="multiple"
										promotion={ promotion }
										isSelected={ selectedPromotions.get('multiple').has(promotion.promotion_id) }
										onSelect={ this.onSelectHandler }
										onApply={ this.onClickApplyHandler }
										onViewDetails={ this.onViewDetailsHandler }
									/>
								))
							}
						</ul>
					</div>

					<div className={ styles.title }>
						{ loc.other }
					</div>
					<div className={ styles.list }>
						<ul className="uk-list uk-list-divider">
							{
								promotions && promotions.other && promotions.other.map((promotion, index) => (
									<PromotionListItem
										key={ `modal-promotions-other-${index}` }
										type="other"
										promotion={ promotion }
										isSelected={
											selectedPromotions.get('exclusive').size === 0 &&
											selectedPromotions.get('multiple').size === 0
										}
										onSelect={ this.onSelectHandler }
										onApply={ this.onClickApplyHandler }
									/>
								))
							}
						</ul>
					</div>
				</div>
			</Fragment>
		);
	}

	renderMessages() {
		const { /* current, type, */ salesOrder, onUpdateMessageRemark } = this.props;
		// const { currentTab } = this.state;
		return (
			<Fragment>
				{/* {
					current === 'cart' && type === 'salesOrder' && (
						<div className={ styles.tab }>
							<Tab
								tabs={ this.tabs }
								current={ currentTab }
								onChange={ this.onChangeTabHandler }
							/>
						</div>
					)
				} */}
				<ul className="uk-list uk-list-divider">
					{
						salesOrder.get('message_data') && salesOrder.get('message_data').map((message, index) => (
							<MessageListItem
								key={ `modal-promotions-message-${index}` }
								index={ index }
								message={ message }
								onUpdateRemark={ onUpdateMessageRemark }
							/>
						))
					}
				</ul>
			</Fragment>
		);
	}

	render() {
		const { isOpen, onToggle } = this.props;
		const { currentTab, viewPromotion } = this.state;
		const isLoading = this.isLoading();
		return (
			<Modal
				isOpen={ !!isOpen }
				onToggle={ onToggle }
				title={ currentTab === 'promotions' ? loc.promotions : loc.notification }
				style={ this.modalStyle }
			>
				<LoadingOverlay active={ isLoading } className={ classnames(styles.loadingOverlay, currentTab === 'promotions' && !viewPromotion && styles.loadingOverlayPromotions) }>
					<div className={ classnames(styles.button, styles.left) } onClick={ this.onClickPrevHandler }>
						<FontAwesomeIcon icon={ faChevronLeft } />
					</div>
					{
						currentTab === 'promotions' && (
							<div className={ classnames(styles.button, styles.right) } onClick={ this.onClickApplyHandler }>
								{ loc.apply }
							</div>
						)
					}
					{
						currentTab === 'notification' && (
							<div className={ classnames(styles.button, styles.right) } onClick={ this.onClickApplyHandler }>
								{ loc.save }
							</div>
						)
					}
					{/* { currentTab === 'promotions' && this.renderPromotions() } */}
					{ currentTab === 'notification' && this.renderMessages() }
				</LoadingOverlay>
			</Modal>
		);
	}
}

ModalPromotions.propTypes = {
	isOpen: PropTypes.string,
	isLoading: PropTypes.bool.isRequired,
	type: PropTypes.string.isRequired,
	current: PropTypes.string.isRequired,
	onToggle: PropTypes.func.isRequired,
	onApply: PropTypes.func,
	onUpdateMessageRemark: PropTypes.func,
	salesOrder: PropTypes.instanceOf(Map).isRequired,
};

export default connect(
	(state) => ({
		giftCouponInfo: state.giftCouponInfo,
	})
)(ModalPromotions);
