import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styles from './MessageListItem.less';

class MessageListItem extends PureComponent {
	constructor() {
		super();
		this.onUpdateRemarkHandler = this.onUpdateRemarkHandler.bind(this);
	}

	onUpdateRemarkHandler(e) {
		const { index, onUpdateRemark } = this.props;
		onUpdateRemark(index, e.target.value);
	}

	render() {
		const { message } = this.props;
		return (
			<li className={ styles.container }>
				<div className={ styles.details }>
					<div className={ styles.message }>
						{ message.content }
					</div>
					{
						message.enable_remark && (
							<div className={ styles.remark }>
								<label>Remark</label>
								<input type="text" className="uk-input" onChange={this.onUpdateRemarkHandler} value={message.remark || ''} />
							</div>
						)
					}
				</div>
			</li>
		);
	}
}

MessageListItem.propTypes = {
	index: PropTypes.number.isRequired,
	message: PropTypes.object.isRequired,
	onUpdateRemark: PropTypes.func.isRequired,
};

export default MessageListItem;
