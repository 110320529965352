exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._1yFBp {\n  margin-top: 0 !important;\n}\n._1yFBp {\n  padding: 10px 20px;\n}\n._1yFBp._2_BiF {\n  cursor: pointer;\n}\n._1yFBp ._23T-Z {\n  display: inline-block;\n  vertical-align: middle;\n  width: 100%;\n  color: #888;\n}\n._1yFBp ._23T-Z ._1Gx9E {\n  font-size: 18px;\n}\n._1yFBp .Vb0Al {\n  padding: 10px;\n  border: 1px solid #ddd;\n  background-color: #f9f9f9;\n  border-radius: 20px;\n  margin: 10px auto 0;\n  font-size: 16px;\n}\n._1yFBp .Vb0Al label {\n  width: 60px;\n  margin-right: 10px;\n}\n._1yFBp .Vb0Al input {\n  width: calc(100% - 70px);\n  font-size: 16px;\n}\n", ""]);

// Exports
exports.locals = {
	"container": "_1yFBp",
	"selectable": "_2_BiF",
	"details": "_23T-Z",
	"message": "_1Gx9E",
	"remark": "Vb0Al"
};